
import { defineComponent, PropType } from "vue";
import { DateRangePickerInterface } from "@/store/interfaces/DateTimeInterface";
import moment from "moment";
import { getEndOfDay } from "@/buying-teams/helpers/DateHelper";

const InvalidDate = "Invalid Date";

export default defineComponent({
    name: "DateRangePicker",
    props: {
        modelValue: {
            type: Object as PropType<DateRangePickerInterface>,
            required: true
        },
        isDisabled: {
            type: Boolean,
            default: false
        },
        allTimeSelectionValue: {
            type: Object as PropType<DateRangePickerInterface>,
            default: null
        }
    },
    emits: ["onTouch", "update:modelValue"],
    data() {
        return {
            datePickerValue: [] as string[]
        };
    },
    created() {
        if (this.modelValue.start_date && this.modelValue.end_date) {
            this.datePickerValue = [
                this.modelValue.start_date,
                this.modelValue.end_date
            ];
        }
    },
    computed: {
        shortcuts() {
            const date = new Date();
            const quarter = Math.floor((date.getMonth() / 3));
            return [
                {
                    text: this.$t("All Time"),
                    value: () => {
                        if (this.allTimeSelectionValue) {
                            return [this.allTimeSelectionValue.start_date, this.allTimeSelectionValue.end_date]
                        }
                        return [null, null];
                    }
                },
                {
                    text: this.$t("Current Month"),
                    value: () => {
                        var start = new Date(date.getFullYear(), date.getMonth(), 1);
                        var end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                        return [start, end];
                    }
                },
                {
                    text: this.$t("Previous Month"),
                    value: () => {
                        let start = new Date(date.getFullYear(), date.getMonth() - 1, 1);
                        let end = new Date(date.getFullYear(), date.getMonth() - 1 + 1, 0);
                        return [start, end];
                    }
                },
                {
                    text: this.$t("Current Quarter"),
                    value: () => {
                        const start = new Date(date.getFullYear(), quarter * 3, 1);
                        const end = new Date(start.getFullYear(), start.getMonth() + 3, 0);
                        return [start, end];
                    }
                },
                {
                    text: this.$t("Previous Quarter"),
                    value: () => {
                        const start = new Date(date.getFullYear(), quarter * 3 - 3, 1);
                        const end = new Date(start.getFullYear(), start.getMonth() + 3, 0);
                        return [start, end];
                    }
                },
                {
                    text: this.$t("Current Year"),
                    value: () => {
                        var start = new Date(date.getFullYear(), 0, 1);
                        var end = new Date(date.getFullYear(), 11, 31);
                        return [start, end];
                    }
                },
                {
                    text: this.$t("Previous Year"),
                    value: () => {
                        var lastYear = new Date(date.getFullYear() - 1, 0, 1);
                        var start = (new Date(lastYear.getFullYear(), 0, 1)).getTime(),
                            end = (new Date(lastYear.getFullYear(), 11, 31)).getTime();
                        return [start, end];
                    }
                }
            ];
        },
        modelValueIsEmpty() {
            return !this.modelValue.start_date && !this.modelValue.end_date;
        },
        getInputPlaceholder() {
            return {
                start_date: this.modelValue.start_date ? moment(this.modelValue.start_date).format("DD MMM ‘YY") : this.$t("Start Date"),
                end_date: this.modelValue.end_date ? moment(this.modelValue.end_date).format("DD MMM ‘YY") : this.$t("End Date")
            };
        }
    },
    methods: {
        updateValue(event) {
            this.$emit("update:modelValue", {
                start_date: event[0] != InvalidDate ? event[0] : "",
                end_date: event[1] != InvalidDate ? getEndOfDay(event[1]) : ""
            });
            this.$emit("onTouch");
        },
        disabledDate(time) {
            if (!this.allTimeSelectionValue) return;
            let date = new Date(this.allTimeSelectionValue.start_date);
            return time.getTime() < date.setDate(date.getDate() - 1);
        },
    },
    watch: {
        modelValue: {
            deep: true,
            handler(newVal) {
                if (!newVal.start_date || !newVal.end_date) {
                    this.datePickerValue = [];
                } else if (newVal.start_date && newVal.end_date) [
                    this.datePickerValue = [
                        newVal.start_date,
                        newVal.end_date
                    ]
                ]
            }
        }
    }
});
