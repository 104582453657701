
export const scoresSortingLevels = [
  {
    label: "Highest score",
    key: 'highest_score'
  },
  {
    label: "Lowest Score",
    key: 'lowest_score'
  },
  {
    label: "Highest Importance Score",
    key: 'highest_importance_score'
  },
  {
    label: "Lowest Importance Score",
    key: 'lowest_importance_score'
  },
  {
    label: "Highest Negative Gap Score",
    key: 'highest_negative_gap'
  },
  {
    label: "Highest Positive Gap Score",
    key: 'highest_positive_gap'
  },
  // {
  //   label: "Highest Standard Deviation",
  //   key: 'highest_standard_deviation'
  // },
  // {
  //   label: "Lowest Standard Deviation",
  //   key: 'lowest_standard_deviation'
  // }
];
