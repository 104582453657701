<template>
    <div class="d-flex justify-content-end align-items-start align-items-md-center flex-column flex-md-row me-3">
        <div class="d-flex align-content-end justify-content-between flex-column flex-sm-row gap-1 gap-sm-4">
            <p class="d-flex align-items-center text-gray-600 m-0">
                {{ $t("Score") }}
            </p>

            <ScoreImportanceInfo />

            <p class="d-flex align-items-center text-gray-600 m-0">
                <span class="d-block bg-gray-400 rounded-circle w-10px h-10px me-2"></span>
                {{ $t("Importance") }}
            </p>
        </div>
    </div>
</template>

<script>
import ScoreImportanceInfo from "@/buying-teams/shared-components/utils/ScoreImportanceInfo";

export default {
    name: "FiltersScoreImportanceInfo",
    components: {
        ScoreImportanceInfo
    }
}
</script>
