<template>
    <div class="business-dashboard-empty-state" :class="className">
        <img :src="icon" alt="">
        <h4>{{ title }}</h4>
        <p>{{ text }}</p>
        <router-link :to="buttonLink" v-if="buttonText">
            <img v-if="buttonIcon" :src="buttonIcon" width="12" alt="">
            <span>{{ buttonText }}</span>
        </router-link>
    </div>
</template>

<script>
export default {
    name: "DashboardEmptyState",
    props: {
        icon: String,
        title: String,
        text: String,
        buttonText: String,
        buttonIcon: String,
        buttonLink: String,
        className: String,
    },

}
</script>

<style scoped lang="scss">
.business-dashboard-empty-state {
    max-width: 500px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding-top: 50px;

    > img {
        margin-bottom: 20px;
    }

    h4 {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #D4D4D4;
        margin-bottom: 8px;
    }

    p {
        font-weight: 400;
        font-size: 12px;
        line-height: 19px;
        text-align: center;
        color: #D4D4D4;
        margin-bottom: 25px;
    }

    a {
        background: #F6F7FE;
        border-radius: 53px;
        height: 37px;
        padding: 11px 36px;
        display: flex;
        align-items: center;
        gap: 10px;

        span {
            font-weight: 700;
            font-size: 14px;
            line-height: 13px;
            color: #435BF4;
        }

        &:hover {
            background: #eeefff;
        }
    }
}
</style>
