import { BankFiltersSelectTypeEnum } from "@/store/enums/bank/BankFiltersSelectTypeEnum";
import { BusinessFiltersSelectTypeEnum } from "@/store/enums/business/BusinessFiltersSelectTypeEnum";

export const filterAllSelectedText = (type: BankFiltersSelectTypeEnum | BusinessFiltersSelectTypeEnum) => {
    switch (type) {
        // BANK
        case BankFiltersSelectTypeEnum.CUSTOMERS : return 'All Selected';
        case BankFiltersSelectTypeEnum.CUSTOMER_TIER : return 'All Selected';
        case BankFiltersSelectTypeEnum.CUSTOMERS_GLOBAL_HQ : return 'All Selected';
        case BankFiltersSelectTypeEnum.INDUSTRIES : return 'All Selected';
        case BankFiltersSelectTypeEnum.COUNTRIES : return 'All Selected';
        case BankFiltersSelectTypeEnum.PRODUCT_AREAS : return 'All Selected';
        case BankFiltersSelectTypeEnum.FEEDBACK_SESSIONS : return 'All Selected';

        // BUSINESS
        case BusinessFiltersSelectTypeEnum.BANKS : return 'All Selected';
        case BusinessFiltersSelectTypeEnum.COUNTRIES : return 'All Selected';
        case BusinessFiltersSelectTypeEnum.PRODUCT_AREAS : return 'All Selected';
        case BusinessFiltersSelectTypeEnum.FEEDBACK_SESSIONS : return 'All Selected';
    }
};

export const filterCustomSelectionText = (type: BankFiltersSelectTypeEnum | BusinessFiltersSelectTypeEnum) => {
    switch (type) {
        // BANK
        case BankFiltersSelectTypeEnum.CUSTOMERS : return 'Multiple Selected';
        case BankFiltersSelectTypeEnum.CUSTOMER_TIER : return 'Multiple Selected';
        case BankFiltersSelectTypeEnum.CUSTOMERS_GLOBAL_HQ : return 'Multiple Selected';
        case BankFiltersSelectTypeEnum.INDUSTRIES : return 'Multiple Selected';
        case BankFiltersSelectTypeEnum.COUNTRIES : return 'Multiple Selected';
        case BankFiltersSelectTypeEnum.PRODUCT_AREAS : return 'Multiple Selected';
        case BankFiltersSelectTypeEnum.FEEDBACK_SESSIONS : return 'Multiple Selected';

        // BUSINESS
        case BusinessFiltersSelectTypeEnum.BANKS : return 'Multiple Selected';
        case BusinessFiltersSelectTypeEnum.COUNTRIES : return 'Multiple Selected';
        case BusinessFiltersSelectTypeEnum.PRODUCT_AREAS : return 'Multiple Selected';
        case BusinessFiltersSelectTypeEnum.FEEDBACK_SESSIONS : return 'Multiple Selected';
    }
};
