<template>
    <div
        class="filter-badges"
        :class="{'not-styles': notStyles}"
        v-if="getSize || forceShow"
    >!
    </div>
</template>

<script>
import store from "@/store";
import { DataService } from "@/core/services/DataService";
import { PlatformTypeEnum } from "@/store/enums/PlatformTypeEnum";
import { DEFAULT_TIME_PERIOD } from "@/store/modules/bank/BankFiltersModule";

export default {
    name: "BusinessFilterAppliedBadges",
    props: {
        filterData: Object,
        allData: Object,
        notStyles: {
            type: Boolean,
            default: false
        },
        forceShow: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        baseFilter() {
            const platformType = DataService.getPlatformType();
            return platformType === PlatformTypeEnum.BUSINESS
                ? store.getters.businessBaseFilterDefaultState.time_period
                : (this.allData.all_time_period ? this.allData.all_time_period : DEFAULT_TIME_PERIOD);
        },
        getSize() {
            let count = 0;

            for (let [key, item] of Object.entries(this.filterData)) {
                const allDataEl = this.allData[`all_${key}`];
                if (key === "time_period") {
                    if (typeof item === 'string') {
                        count += item === '*' ? 0 : 1;
                    } else {
                        count += (!!(item.start_date && new Date(item.start_date).getTime() === new Date(this.baseFilter.start_date).getTime()) ||
                            !!(item.end_date && new Date(item.end_date).getTime() === new Date(this.baseFilter.end_date).getTime())) ? 0 : 1;
                    }
                }
                if (allDataEl) {
                    count += item.length === allDataEl.length || !item.length ? 0 : 1;
                }
            }

            this.$emit("getSize", count);
            return count;
        }
    }
};
</script>

<style scoped lang="scss">
.filter-badges:not(.not-styles) {
    width: 21px;
    height: 21px;
    background: #E22D21;
    border: 1px solid #FFFFFF;
    box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.2);
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
</style>
