<template>
  <div class="d-flex align-items-center justify-content-center text-gray-600 m-0 score-importance-info-section">
    <p class="text-gray-600 m-0 d-flex align-items-center me-3">
      <span class="d-block bg-danger rounded-circle w-10px h-10px me-2"></span>
      <span class="values">1.00 - 2.49</span>
    </p>
    <p class="text-gray-600 m-0 d-flex align-items-center me-3">
      <span class="d-block bg-warning rounded-circle w-10px h-10px me-2"></span>
      <span class="values">2.50 - 3.49</span>
    </p>
    <p class="text-gray-600 m-0 d-flex align-items-center">
      <span class="d-block bg-success rounded-circle w-10px h-10px me-2"></span>
      <span class="values">3.50 - 5.00</span>
    </p>
  </div>
</template>

<script>
export default {
  name: "ScoreImportanceInfo"
};
</script>

<style lang="scss" scoped>
.score-importance-info-section {
  padding: 4px 8px;
  border: 1px solid #D9D9D9;
  border-radius: 6px;

  .values {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
    opacity: 0.8;
  }

}
</style>
